import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../views/home";
import TenentId from "../views/tenentId";
import Landing from "../views/landing";
import SvButton from "../views/svButton";
import Success from "../views/success";
import Error from "../views/error";
import DataForm from "../views/dataForm";
import Verify from "../views/verify";
import Idp from "../views/idp";
import Expired from "../views/expired";


const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/home" element={<Home />} />
      <Route path="/:tenentId" element={<TenentId />} />
      <Route exact path="/:tenentId/landing" element={<Landing />} />
      <Route exact path="/:tenentId/sv-button" element={<SvButton />} />
      <Route exact path="/:tenentId/success" element={<Success />} />
      <Route exact path="/:tenentId/error" element={<Error />} />
      <Route exact path="/:tenentId/form" element={<DataForm />} />
      <Route exact path="/:tenentId/verify" element={<Verify />} />
      <Route exact path="/:tenentId/idp" element={<Idp />} />
      <Route exact path="/:tenentId/expired" element={<Expired />} />
    </Routes>
  );
};

export default AppRoutes;
