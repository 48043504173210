import React from "react";
import { useSelector } from "react-redux";
import layouts from "../../components/layouts";
import Form from "../../components/form";

const DataForm = () => {
  const { theme, formData } = useSelector((state) => state.tenent);

  return layouts[theme]({
    children: (
      <section
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Form formData={formData} />
      </section>
    ),
  });
};

export default DataForm;
