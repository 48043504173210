import React from "react";
import T1Nav from "../navbar/t1Nav";

const T1Layout = ({ children }) => {
  return (
    <main>
      <T1Nav />
      {children}
    </main>
  );
};

export default T1Layout;
