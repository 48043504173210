import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import DateField from "../components/inputFields/datePickerField";
import PasswordField from "../components/inputFields/passwordField";

const getFields = ({ data, inputConfig }) => {
  const typeOfInputs = {
    TextField: (
      <FormControl margin="dense" key={data.id} fullWidth>
        <TextField {...inputConfig} />
      </FormControl>
    ),
    Select: ({ data, inputConfig }) => {
      return (
        <FormControl margin="dense" key={data.id} fullWidth>
          <InputLabel id={`select-label-${data.name}`}>{data.name}</InputLabel>
          <Select labelId={`select-label-${data.name}`} {...inputConfig}>
            {data.menuItems?.map((item) => {
              return (
                <MenuItem key={item.key} value={item.key}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    },
    CheckBox: (
      <FormGroup key={data.id}>
        <FormControlLabel
          control={<Checkbox {...inputConfig} />}
          label={data.name}
        />
      </FormGroup>
    ),
    Date: (
      <FormControl margin="dense" key={data.id} fullWidth>
        <DateField key={data.id} data={data} conf={inputConfig} />
      </FormControl>
    ),
    Password: (
      <FormControl margin="dense" key={data.id} fullWidth>
        <PasswordField key={data.id} data={data} conf={inputConfig} />
      </FormControl>
    ),
  };
  if (data.field === "Select") {
    return typeOfInputs[data.field]({ data, inputConfig });
  }
  return typeOfInputs[data.field];
};

const getFormFields = (formData = []) => {
  const fields = {};

  formData?.forEach((e) => {
    if (e.field === "Date") {
      fields[e.label] = null;
    } else {
      fields[e.label] = "";
    }
  });
  return fields;
};

export { getFields, getFormFields };
