import "../../styles/layouts/t3Layout.scss"
import T3Nav from "../navbar/t3Nav";
import T3Footer from "../footer/t3Footer";

const T3Layout = ({ children }) => {
  return (
    <main className="t3layout">
      <T3Nav />
      <div style={{ flex: 1 }}>{children}</div>
      <T3Footer />
    </main>
  );
};

export default T3Layout;
