import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";

import { sharedApiManager } from "../apiManager";
import { getFields, getFormFields } from "../utils";

const Form = ({ formData }) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { tenentId, tenent, payload, iv } = useSelector((state) => state.tenent);
  const [sFdata, setSFData] = useState([]);
  const [fields, setFields] = useState(getFormFields(formData));
  const [formError, setFormError] = useState("");

  const SuccessPath = `/${tenent}/success`;
  const ErrorPath = `/${tenent}/error`;

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      setFields((prev) => ({ ...prev, [name]: checked }));
    } else {
      setFields((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    const token = searchParams.get("token");

    // Required fields validation

    const requiredFields = sFdata.filter((e) => e.required && !fields[e.label]);

    if (requiredFields.length) {
      let errorString = "";
      requiredFields.forEach(
        (e) => (errorString = errorString + `${e.name}, `)
      );
      setFormError(() => `${errorString} are required fields`);

      setTimeout(() => {
        setFormError(() => "");
      }, 4000);
      return null;
    }

    try {
      const response = await sharedApiManager.authorProfileUpdate(
        { fields },
        token,
        tenentId,
        payload,
        iv 
      );

      if (response) {
        navigate(`${SuccessPath}?token=${token}`);
      } else {
        navigate(ErrorPath);
      }
    } catch (err) {
      navigate(ErrorPath);
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");

    if (!token) {
      navigate(ErrorPath);
    }

    setSFData(() => [...formData]);
    const getDropDownValues = async () => {
      const filterFormData = formData.filter(
        (e) => e.url && e.method === "get" && e.field === "Select"
      );

      for (const element of filterFormData) {
        const list = await fetch(element.url).then((resp) => resp.json());
        setSFData((prev) =>
          prev.map((e) => {
            if (element?.id === e?.id) {
              e.menuItems = list.data;
            }

            return e;
          })
        );
      }
    };

    getDropDownValues();
  }, []);

  return (
    <Box
      style={{
        maxWidth: "500px",
        width: "95%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {sFdata
        ?.filter((d) => d.id && d.label && d.name)
        .map((e) => {
          return getFields({
            data: e,
            inputConfig: {
              ...e?.additionalConfig,
              key: e?.id,
              id: e?.label,
              label: e?.name,
              name: e?.label,
              value: fields[e?.label],
              onChange: handleChange,
            },
          });
        })}

      {formError && <Alert severity="error">{formError}</Alert>}

      <Button
        style={{ background: "#3928d6" }}
        onClick={handleSubmit}
        variant="contained"
        margin="normal"
      >
        Submit
      </Button>
    </Box>
  );
};

export default Form;
