import React, { useEffect } from "react";
import layouts from "../../components/layouts";
import { useSelector } from "react-redux";
import { useInitFunction } from "../../helpers";

const Landing = () => {
  const { theme, uiConfig, type } = useSelector((state) => state.tenent);

  const { func } = useInitFunction();

  useEffect(() => {
    func[type]();
  }, []);

  return layouts[theme]({
    children: (
      <section className="container">
        <div
          className="landing"
          style={{ backgroundColor: uiConfig?.colorTheme?.textBackground }}
        >
          <h2
            className="landing-heading"
            style={{
              color: uiConfig?.colorTheme?.headingTextColor,
              borderBottomColor: uiConfig?.colorTheme?.underLineColor,
            }}
          >
            {uiConfig?.landingPage?.heading}
          </h2>
          <h3
            className="landing-valtext"
            style={{ color: uiConfig?.colorTheme?.subTextColor }}
          >
            {uiConfig?.landingPage?.subHeading}
          </h3>
          {uiConfig?.landingPage?.otherText?.map((e) => {
            return (
              <p
                key={e}
                className="landing_typo"
                style={{ color: uiConfig?.colorTheme?.subTextColor }}
                dangerouslySetInnerHTML={{ __html: e }}
              ></p>
            );
          })}
          <div
            className="loader"
            style={{ borderTopColor: uiConfig?.colorTheme?.spinnerColor }}
          ></div>
        </div>
      </section>
    ),
  });
};

export default Landing;
