import axios from "axios";
import { API_LOCAL, API, PHASE } from "../phaseConstants";

let headers = {};
const apiManager = {
  API_URL: API,

  customSsoLogin: async function (url, channel, tenantId) {
    try {
      window.location =  url + `/auth/login/${tenantId}/${channel}`;
      return true;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  ssoLogin: async function (channel, tenantId) {
    try {
      window.location = this.API_URL + `/auth/login/${tenantId}/${channel}`;
      return true;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  getFeConfig: async function (tenantId) {
    try {
      const data = await axios
        .get(`${this.API_URL}/tenant/config/fe-sv-${tenantId}`)
        .then((res) => res.data);
      return data;
    } catch (err) {
      throw err;
    }
  },
  userInit: async function (payload, tenantId, iv, svUID) {
    try {
      const data = {
        payload: payload,
        tenantId: tenantId,
        iv: iv,
        svUID: svUID,
      };
      console.log(data)

      var config = {
        method: "post",
        url: `${this.API_URL}/user/token/generate`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const request = await axios(config);
      const responseData = request?.response?.data
        ? request.response.data
        : request.data;

      if (responseData.status === 200) {
        sessionStorage.setItem("token", responseData.data.jwt);
      }
      return responseData;
    } catch (ex) {
      console.log("ex", ex);
      return { status: 500 };
    }
  },
  authorProfileUpdate: async function (
    fields,
    queryToken,
    tenantId,
    payload,
    iv
  ) {
    try {
      headers.authorization = "Bearer " + sessionStorage.getItem("token");

      const request = await axios.post(
        this.API_URL + "/author/profile/update",
        { ...fields, tenantId, queryToken, payload, iv },
        { headers }
      );

      const responseData = request?.response?.data
        ? request.response.data
        : request.data;

      if (responseData.status === 200) {
        sessionStorage.setItem("token", responseData.data.jwt);
      }
      return responseData;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  getModernChatInfo: async function (tenantId, payload) {
    try {
      headers.authorization = "Bearer " + sessionStorage.getItem("token");

      const response = await axios.post(
        this.API_URL + `/chat/info`,
        { tenantId, payload },
        {
          headers,
        }
      );

      return response.data;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  idpUpdate: async function (url, tenantId, payload, queryToken) {
    try {
      headers.authorization = "Bearer " + sessionStorage.getItem("token");

      const response = await axios.post(
        url,
        { queryToken, tenantId, payload },
        { headers }
      );

      return response;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
  urlShrink: async function (longUrl, tenantId, payload) {
    try {
      const response = await axios.post(`${this.API_URL}/url/shrink`, {
        longUrl,
        tenantId,
        payload,
      });

      return response.data;
    } catch (ex) {
      console.log(ex);
      return { status: 500 };
    }
  },
  getSecureVerificationInvite: async function (tenantId, payload, isExternalRequest) {
    try {

      const response = await axios.post(
        this.API_URL + `/invite/new`,
        { tenantId, payload, isExternalRequest },
        {
          headers,
        }
      );

      return response.data.inviteUrl;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  },
};

export default apiManager;
