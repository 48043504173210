import React from "react";

const Home = () => {
  return (
    <section className="container">
      <div className="home">
        <h1 className="home-heading">
          Powered by <br /> Khoros
        </h1>
      </div>
    </section>
  );
};

export default Home;
