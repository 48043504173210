import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const T1Nav = () => {
  const { logo, favicon, uiConfig } = useSelector((state) => state.tenent);

  useEffect(() => {
    if (favicon) {
      document.title = uiConfig.title;
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    return () => {};
  }, []);
  return (
    <nav className="t1nav">
      <div className="container">
        <img
          style={{ height: uiConfig?.logoHeight || "50px" }}
          src={logo}
          alt=""
          className="t1nav-logo"
        />
      </div>
    </nav>
  );
};

export default T1Nav;
