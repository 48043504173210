import React from "react";
import { useSelector } from "react-redux";

const SuccessComp = () => {
  const { uiConfig } = useSelector((state) => state.tenent);
  return (
    <div
      className="success"
      style={{ backgroundColor: uiConfig?.colorTheme?.textBackground }}
    >
      <h1
        className="success_heading"
        style={{
          color: uiConfig?.colorTheme?.headingTextColor,
          borderBottomColor: uiConfig?.colorTheme?.underLineColor,
        }}
      >
        {uiConfig?.successPage?.heading}
      </h1>
      <p
        className="success_typo"
        style={{ color: uiConfig?.colorTheme?.subTextColor }}
      >
        {uiConfig?.successPage?.subHeading}
      </p>
      {uiConfig?.successPage?.otherText?.map((e) => {
        return (
          <p
            key={e}
            className="success_typo"
            style={{ color: uiConfig?.colorTheme?.subTextColor }}
            dangerouslySetInnerHTML={{ __html: e }}
          ></p>
        );
      })}
    </div>
  );
};

export default SuccessComp;
