import T1Layout from "./t1Layout";
import T2Layout from "./t2Layout";
import T3Layout from "./t3Layout";

const layouts = {
  T1Layout,
  T2Layout,
  T3Layout,
};

export default layouts;
