import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { sharedApiManager } from "../apiManager";

const useUpdateFunction = () => {
  const { payload, tenentId, tenent, uiConfig } = useSelector(
    (state) => state.tenent
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("sso_code");
  const ERROR_PATH = `/${tenent}/error`;
  const SUCCESS_PATH = `/${tenent}/success`;

  const func = {
    svIDP: async function () {
      if (!token) {
        return navigate(ERROR_PATH);
      }
      if (!payload) {
        navigate(ERROR_PATH);
      } else if (payload) {
        const data = await sharedApiManager.idpUpdate(
          uiConfig.customUpdate,
          tenentId,
          payload,
          token
        );

        if (!data) {
          navigate(ERROR_PATH);
        } else {
          navigate(SUCCESS_PATH);
        }
      }
    },
    svCustomIDP: async function () {
      if (!token) {
        return navigate(ERROR_PATH);
      }
      if (!payload) {
        navigate(ERROR_PATH);
      } else if (payload) {
        const data = await sharedApiManager.idpUpdate(
          uiConfig.customUpdate,
          tenentId,
          payload,
          token
        );

        if (!data) {
          navigate(ERROR_PATH);
        } else {
          navigate(SUCCESS_PATH);
        }
      }
    },
  };

  return {
    func,
  };
};

export default useUpdateFunction;
