import React from "react";
import T2Nav from "../navbar/t2Nav";

const T1Layout = ({ children }) => {
  return (
    <main>
      <T2Nav />
      {children}
    </main>
  );
};

export default T1Layout;
