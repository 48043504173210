import React, { useState } from "react";
import { sharedApiManager } from "../../apiManager";
import { useParams } from "react-router-dom";


const SvButton = () => {
  const [loading, setLoading] = useState(false);
  const { tenentId } = useParams();

  const populateTextArea = async (text) => {
    console.log(text);
    window.top.postMessage(JSON.stringify({ appendResponse: text }), "*");
  };

  const payload = window.location.search.replace("?payload=", "");

  const populateCRMTransfer = async (event) => {
    event.preventDefault();
    setLoading(true);

    const longUrl = await sharedApiManager.getSecureVerificationInvite(
      `sv-${tenentId}`,
      payload,
      false
    );

    const inviteInfo = await sharedApiManager.urlShrink(
      longUrl,
      `sv-${tenentId}`,
      payload
    );
    const { status, message } = inviteInfo
    const { shortLink } = inviteInfo?.data;


    if (status === 500) {
      populateTextArea(longUrl);
    }
    populateTextArea(message.replace("(link)", shortLink));

    setLoading(false);
  };

  return (
    <>
      <section className="container">
        <div className="svbutton">
          <a style={{ cursor: "pointer" }} onClick={populateCRMTransfer}>
            <div className="svbutton_button">
              <p>Invite to Secure Verification</p>
            </div>
          </a>
        </div>
      </section>
      {loading && (
        <div className="abs center loader-bg">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default SvButton;
