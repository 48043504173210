import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { sharedApiManager } from "../apiManager";
import { setInitData } from "../store/reducer/tenent";

const useInitFunction = () => {
  const { payload, iv, tenentId, tenent, svUID, uiConfig } = useSelector(
    (state) => state.tenent
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ERROR_PATH = `/${tenent}/error`;
  const EXPIRED_PATH = `/${tenent}/expired`;
  const FORM_PATH = `/${tenent}/form`;

  const func = {
    svIDP: async function () {
      if (!payload) {
        navigate(ERROR_PATH);
      } else if (payload) {
        const data = await sharedApiManager.userInit(
          payload,
          tenentId,
          iv,
          svUID
        );

        if (data.invalidInvitation) {
          return navigate(EXPIRED_PATH);
        } else if (data?.status !== 200) {
          return navigate(ERROR_PATH);
        } else {
          dispatch(setInitData(data.data));
          await sharedApiManager.ssoLogin("idp", tenentId);
        }
      }
    },
    stStandard: async function () {
      if (!payload) {
        navigate(ERROR_PATH);
      } else if (payload) {
        const data = await sharedApiManager.userInit(payload, tenentId, iv);

        if (data.invalidInvitation) {
          return navigate(EXPIRED_PATH);
        } else if (data?.status !== 200) {
          return navigate(ERROR_PATH);
        } else {
          dispatch(setInitData(data.data));
          return navigate(`${FORM_PATH}?token=${data.data.jwt}`);
        }
      }
    },
    svCustomIDP: async function () {
      if (!payload) {
        navigate(ERROR_PATH);
      } else if (payload) {
        const data = await sharedApiManager.userInit(
          payload,
          tenentId,
          iv,
          svUID
        );

        if (data.invalidInvitation) {
          return navigate(EXPIRED_PATH);
        } else if (data?.status !== 200) {
          return navigate(ERROR_PATH);
        } else {
          dispatch(setInitData(data.data));
          await sharedApiManager.customSsoLogin(
            uiConfig.customSSO,
            "idp",
            tenentId
          );
        }
      }
    },
  };

  return {
    func,
  };
};

export default useInitFunction;
