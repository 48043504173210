import React, { useState, useEffect } from "react";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isMobile,
  isMobileOnly,
  isIOS,
  withOrientationChange,
  isTablet,
} from "react-device-detect";
import { useSelector } from "react-redux";
import { sharedApiManager } from "../apiManager";

import { makeStyles } from "@mui/styles";
import { Grid, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
    padding: "0 20",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  requiredLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: "0 0 0 8px",
  },
  modernChatDesktop: {
    left: "20% !important",
    width: "60% !important",
    bottom: "10% !important",
    position: "relative !important",
    maxWidth: "100% !important",
    minHeight: "600px !important",
    maxHeight: "100% !important",
  },
  modernChatMobile: {
    maxHeight: "95% !important",
    position: "relative !important",
    width: "86% !important",
    left: "7% !important",
    minHeight: "550px !important",
  },
  modernChatMobileLandscape: {
    width: "70% !important",
    minHeight: "500px !important",
    left: "15% !important",
    maxHeight: "100% !important",
    position: "relative !important",
  },
  modernChatTablet: {
    left: "10% !important",
    position: "relative !important",
    maxHeight: "100% !important",
    minHeight: "600px !important",
    width: "80% !important",
  },
  modernChatTabletLandscape: {
    minWidth: "70% !important",
    left: "15% !important",
    maxHeight: "100% !important",
    minHeight: "600px !important",
    position: "relative !important",
  },
  modernChatContent: {
    width: "100% !important",
  },
  title: {
    flexGrow: 1,
    borderBottom: "4px solid #0072CE",
    fontSize: "2.33em",
    lineHeight: 1.25,
    fontWeight: "bold",
  },
  description: {
    lineHeight: 1.55,
    margin: "0.5em 0",
  },
  button: {
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: 1,
    borderRadius: "4px",
    letterSpacing: "0.04em",
    padding: "15px 20px",
    fontFamily: '"Montserrat", sans-serif',
    "&:hover": {
      opacity: 0.7,
    },
  },
  customForm: {
    paddingTop: 20,
    margin: "0 auto",
  },
  progressWrapper: {
    paddingBottom: "2rem",
  },
  progress: {
    margin: "auto",
  },
  bodyDominion: {
    backgroundColor: "#E2E2E2",
    paddingRight: "0px",
    paddingLeft: "0px",
  },
}));

let BrandMessenger = (props) => {
  const { tenentId, tenent, payload } = useSelector((state) => state.tenent);
  const ErrorPath = `/${tenent}/error`;
  const history = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const { isLandscape, isPortrait } = props;

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [chatData, setChatData] = useState(null);

  const getModernChatData = async () => {
    const queryToken =
      history.search.indexOf("token") > -1
        ? history.search.replace("?token=", "")
        : false;

    if (!queryToken) {
      navigate(ErrorPath);
    } else {
      const responseData = await sharedApiManager.getModernChatInfo(
        tenentId,
        payload
      );

      if (responseData) {
        setChatData(responseData);
      } else {
        navigate(ErrorPath);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getModernChatData();

    setInit(true);
  }, []);

  const injectScript = () => {
    window.addEventListener("khorosInit", async (event) => {
      console.log("khorosinit");
      const { givenName, properties } = chatData;
      if (event.detail) {
        event.detail.sdk.updateUser({
          givenName,
          properties,
        });
      }

      setModernChatStyles();

      if (window.Brandmessenger) {
        window.Brandmessenger.open();
      }

      setLoading(false);
    });

    const { companyKey, appId, widgetId, jwt, userId } = chatData;

    window.KHOROS_CONFIG = {
      companyKey: companyKey,
      appId: appId,
      widgetId: widgetId,
      jwt,
      userId,
    };

    var scriptTag = document.createElement("script");
    scriptTag.setAttribute(
      "src",
      "https://brand-messenger.app.khoros.com/bundle/loader.js?v=" +
        new Date().getTime()
    );
    document.head.appendChild(scriptTag);
  };

  useEffect(() => {
    if (window.Brandmessenger) {
      window.Brandmessenger.destroy();
    }

    if (chatData) {
      injectScript();
    }
  }, [chatData]);

  const setModernChatStyles = () => {
    const desktopStyles = classes.modernChatDesktop;
    const mobileStyles = classes.modernChatMobile;
    const mobileLandscapeStyles = classes.modernChatMobileLandscape;
    const tabletStyles = classes.modernChatTablet;
    const tabletLandscapeStyles = classes.modernChatTabletLandscape;
    let webMessengerSelector = $("#web-messenger-container");

    if (webMessengerSelector && window && window.innerWidth > 767) {
      if ((isMobileOnly || isIOS) && !isTablet) {
        webMessengerSelector.addClass(
          isLandscape ? mobileLandscapeStyles : mobileStyles
        );
        webMessengerSelector.removeClass(
          isLandscape ? mobileStyles : mobileLandscapeStyles
        );
        webMessengerSelector.removeClass(desktopStyles);
        webMessengerSelector.removeClass(tabletStyles);
      } else if (isTablet || isIOS) {
        webMessengerSelector.addClass(
          isLandscape ? tabletLandscapeStyles : tabletStyles
        );
        webMessengerSelector.removeClass(mobileStyles);
        webMessengerSelector.removeClass(mobileLandscapeStyles);
        webMessengerSelector.removeClass(desktopStyles);
      } else {
        webMessengerSelector.addClass(desktopStyles);
        webMessengerSelector.removeClass(mobileStyles);
        webMessengerSelector.removeClass(mobileLandscapeStyles);
        webMessengerSelector.removeClass(tabletStyles);
        webMessengerSelector.removeClass(tabletLandscapeStyles);
      }
      webMessengerSelector
        .contents()
        .find("#wrapper")
        .css({ width: "100%", height: "80% !important" });
    } else {
      webMessengerSelector.removeClass(desktopStyles);
      webMessengerSelector.removeClass(mobileStyles);
      webMessengerSelector.removeClass(mobileLandscapeStyles);
      webMessengerSelector.removeClass(tabletStyles);
      webMessengerSelector.removeClass(tabletLandscapeStyles);
    }
    $("#web-messenger-container")
      .contents()
      .find("#khoros-firstMessageChatWrapper")
      .css({ display: "none" });
  };

  $("#web-messenger-container")
    .contents()
    .find("#khoros-firstMessageChatWrapper")
    .css({ display: "none" });

  useEffect(() => {
    setModernChatStyles();
    $("#khoros-firstMessageChatWrapper").remove();
  }, [isPortrait, isLandscape, loading, setModernChatStyles]);

  useEffect(() => {
    document.body.className = classes.bodyDominion;
  }, [classes.v]);

  return (
    <div className={classes.root}>
      <div
        id="overlay"
        style={{
          height: 0,
          background: "#fff",
          zIndex: 234234,
          position: "absolute",
          width: "100%",
          left: "0px",
          top: "92px",
        }}
      >
        <br />
        <div style={{ margin: "0 auto", width: "50%" }}></div>
        <br />
      </div>
      <div className={classes.content}>
        <Grid container>
          {loading && <CircularProgress className={classes.progress} />}
        </Grid>
      </div>
    </div>
  );
};

BrandMessenger = withOrientationChange(BrandMessenger);

export default BrandMessenger;
