import React from "react";
import { useSelector } from "react-redux";
import layouts from "../../components/layouts";
import GenericError from "../../components/genericError";

const Error = () => {
  const { theme, uiConfig } = useSelector((state) => state.tenent);

  if (!uiConfig.errorPage) {
    return <GenericError />;
  }

  return layouts[theme]({
    children: (
      <section className="container">
        <div
          className="error"
          style={{ backgroundColor: uiConfig?.colorTheme?.textBackground }}
        >
          <h1
            className="error_heading"
            style={{ color: uiConfig?.colorTheme?.headingTextColor, borderBottomColor: uiConfig?.colorTheme?.underLineColor }}
          >
            {uiConfig?.errorPage?.heading}
          </h1>
          <p
            className="error_typo"
            style={{ color: uiConfig?.colorTheme?.subTextColor }}
          >
            {uiConfig?.errorPage?.subHeading}
          </p>
          {uiConfig?.errorPage?.otherText?.map((e) => {
            return (
              <p
                key={e}
                className="error_typo"
                style={{ color: uiConfig?.colorTheme?.subTextColor }}
                dangerouslySetInnerHTML={{ __html: e }}
              ></p>
            );
          })}
        </div>
      </section>
    ),
  });
};

export default Error;
