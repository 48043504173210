import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import layouts from "../../components/layouts";
import { useUpdateFunction } from "../../helpers/index";

const Idp = () => {
  const { theme, type, uiConfig } = useSelector((state) => state.tenent);

  const { func } = useUpdateFunction();

  useEffect(() => {
    func[type]();
  }, []);

  return layouts[theme]({
    children: (
      <section
        style={{
          height: "calc(100vh - 100px)",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="loader"
          style={{ borderTopColor: uiConfig?.colorTheme?.spinnerColor }}
        ></div>
      </section>
    ),
  });
};

export default Idp;
