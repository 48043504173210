const GenericError = () => {
  return (
    <section className="container">
      <div className="gerror">
        <h1 className="gerror_heading">ERROR :(</h1>
        <p className="gerror_typo">
          Please, go back to the chat with your agent to receive further help!
        </p>
      </div>
    </section>
  );
};

export default GenericError;
