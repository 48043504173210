import React, { useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sharedApiManager } from "../../apiManager";
import { setTheme } from "../../store/reducer/tenent";

const TenentId = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tenentId } = useParams();

  let [searchParams] = useSearchParams();

  useEffect(() => {
    async function getConfig() {
      const payload = searchParams.get("payload");
      const iv = searchParams.get("iv");
      const brand = searchParams.get("brand");
      const svUID = searchParams.get("svUID");

      await sharedApiManager
        .getFeConfig(tenentId)
        .then((res) => {
          dispatch(
            setTheme({
              ...res,
              payload,
              iv,
              brand,
              svUID,
              tenentId: `sv-${tenentId}`,
              tenent: tenentId,
            })
          );
          navigate(`/${tenentId}/landing`);
        })
        .catch((err) => {
          navigate(`/${tenentId}/error`);
        });
    }
    if (tenentId) {
      getConfig();
    }
  }, [dispatch, navigate, tenentId]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="loader"></div>
    </div>
  );
};

export default TenentId;
