import React from "react";
import { useSelector } from "react-redux";
import layouts from "../../components/layouts";
import BrandMessenger from "../../components/brandMessener";
import SuccessComp from "../../components/success";

const Success = () => {
  const { theme, type } = useSelector((state) => state.tenent);
  const comp = {
    svStandard: <SuccessComp />,
    svIDP: <SuccessComp />,
    svCustomIDP: <SuccessComp />,
    svEnhanced: <SuccessComp />,
    stStandard: <BrandMessenger />,
    stIDP: <BrandMessenger />,
    stEnhanced: <BrandMessenger />,
  };
  return layouts[theme]({
    children: <section className="container">{comp[type]}</section>,
  });
};

export default Success;
