import { createSlice } from "@reduxjs/toolkit";

export const tenentSlice = createSlice({
  name: "counter",
  initialState: {
    tenentId: "",
    tenent: "",
    logo: "",
    favicon: "",
    type: "",
    theme: "",
    uiConfig: {},
    payload: "",
    decryptedPayload: {},
    jwt: "",
    iv: "",
    brand: "",
    ssoNetwork: "",
    formData: [],
    token: "",
    svUID: ""
  },
  reducers: {
    setTheme: (state, { payload }) => {
      state.logo = payload?.logo;
      state.favicon = payload?.favicon;
      state.type = payload?.type;
      state.theme = payload?.theme;
      state.uiConfig = payload?.uiConfig;
      state.formData = payload?.formData;
      state.payload = payload?.payload;
      state.svUID = payload?.svUID;
      state.iv = payload?.iv;
      state.brand = payload?.brand;
      state.tenentId = payload?.tenentId;
      state.tenent = payload?.tenent;
    },
    setInitData: (state, { payload }) => {
      state.decryptedPayload = payload?.decryptedPayload;
      state.jwt = payload?.jwt;
      state.ssoNetwork = payload?.ssoNetwork;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTheme, setInitData } = tenentSlice.actions;

export default tenentSlice.reducer;
