import { useSelector } from "react-redux";

const T3Footer = () => {
  const uiConfig = useSelector((state) => state.tenent.uiConfig);

  return (
    <footer className="t3footer">
      <p>{uiConfig?.footerText}</p>
    </footer>
  );
};

export default T3Footer;
